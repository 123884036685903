import VueCropper from "vue-cropperjs";
import "cropperjs/dist/cropper.css";
import { quality } from "gurhan/helpers/Image";
import AlertBox from "../mixins/AlertBox";
function dataURItoBlob(dataURI) {
  // convert base64/URLEncoded data component to raw binary data held in a string
  var byteString;
  if (dataURI.split(",")[0].indexOf("base64") >= 0)
    byteString = atob(dataURI.split(",")[1]);
  else byteString = unescape(dataURI.split(",")[1]);
  // separate out the mime component
  var mimeString = dataURI.split(",")[0].split(":")[1].split(";")[0];
  // write the bytes of the string to a typed array
  var ia = new Uint8Array(byteString.length);
  for (var i = 0; i < byteString.length; i++) {
    ia[i] = byteString.charCodeAt(i);
  }
  return {
    file: new Blob([ia], { type: mimeString }),
    blob: URL.createObjectURL(new Blob([ia], { type: mimeString })),
  };
}

export default {
  mixins: [AlertBox],
  components: { VueCropper },
  props: {
    image: {
      default: "",
    },
  },
  data() {
    return {
      imageIsCropping: false,
      sizes: { width: 740, height: 470 },
      resultImage: "",
      imgSrc: "",
      imgFile: null,
      alertBox: {
        status: false,
        message: "",
        variant: "danger",
      },
    };
  },
  methods: {
    // crop image
    async CROP_IMAGE(size = 750) {
      this.imageIsCropping = true;
      const image = this.$refs.cropper.getCroppedCanvas().toDataURL();
      const result = dataURItoBlob(image);
      const response = await quality(result.file, size, 1);
      this.resultImage = response.result;
      this.$emit("change:image", { uri: response.result, file: response.file });
      this.imgSrc = "";
      this.imageIsCropping = false;
    },
    // get image from input:file and check is it above this.sizes
    SET_IMAGE(e) {
      this.alertBox.status = false;
      const vm = this;
      const file = e.target.files[0];
      if (file.size > 50000000)
        return this.SET_ALERT_BOX("danger", this.$t("more50mb"));
      if (file.type.indexOf("image/") === -1) {
        alert("Please select an image file");
        return;
      }
      if (typeof FileReader === "function") {
        const reader = new FileReader();
        reader.onload = (event) => {
          const img = new Image();
          img.onload = function () {
            console.log(vm.sizes);
            if (img.width < vm.sizes.width || img.height < vm.sizes.height)
              return (vm.alertBox = {
                status: true,
                message: vm.$t("imageSizeAtLeast", {
                  width: vm.sizes.width,
                  height: vm.sizes.height,
                }),
                variant: "danger",
              });
            vm.imgSrc = event.target.result;
            vm.imgFile = file;
            vm.resultImage = "";
          };
          img.src = event.target.result;
          // rebuild cropperjs with the updated source
          setTimeout(() => {
            if (this.$refs.cropper) {
              this.$refs.cropper.replace(event.target.result);
              console.log(this.$refs.cropper.setCropBoxData);
              setTimeout(() => {
                this.$refs.cropper.setCropBoxData({ top: 0 });
              }, 100);
            }
          }, 200);
        };
        reader.readAsDataURL(file);
      } else {
        alert("Sorry, FileReader API not supported");
      }
    },
    // reset image when product is editting
    SET_TO_RESET_IMAGE() {
      this.imgSrc = "";
      this.resultImage = this.image;
      this.$emit("change:image", { uri: "", file: null });
      this.imageIsCropping = false;
    },
  },
  mounted() {
    console.log(this.image, "image");
    this.resultImage = this.image;
  },
};
